export const projects = [
  {
    id: 1,
    title: 'Leaf Lens',
    subtitle: ' ',
    tagline: 'Identify Any Plant Instantly',
    description:
      'Take a photo or upload an image of any plant, and our AI will help you identify and learn about it.',
    image:
      'https://firebasestorage.googleapis.com/v0/b/blogify-9a1bd.appspot.com/o/Leaf-Lens.png?alt=media&token=01a623db-4a93-4feb-bf0a-c19ed154b502',
    features: [
      'Identify Plant from a picture',
      'Identify Plant from a camera',
      'Search History'
    ],
    demoUrl: 'http://greenlens.tech/',
    theme: {
      color: '#10B981',
      secondary: '#14B8A6'
    }
  },
  {
    id: 2,
    title: 'Canvas',
    subtitle: '',
    tagline: 'Micro Monetization for Creators',
    description:
      'Canvas is a platform that allows creators to monetize their content through microtransactions.',
    image: '/canvas.png',
    features: [
      'Micro Monetization for Creators',
      'Content Creation Tools',
      'Text, Image, Audio, and Video Support'
    ],
    demoUrl: 'https://canvas.space/',
    theme: {
      color: '#f7901e',
      secondary: '#31348e'
    }
  },
  {
    id: 2,
    title: 'Canvas',
    subtitle: '',
    tagline: 'Micro Monetization for Creators',
    description:
      'Canvas is a platform that allows creators to monetize their content through microtransactions.',
    image: '/canvas.png',
    features: [
      'Micro Monetization for Creators',
      'Content Creation Tools',
      'Text, Image, Audio, and Video Support'
    ],
    demoUrl: 'https://canvas.space/',
    theme: {
      color: '#f7901e',
      secondary: '#31348e'
    }
  }
];
